

import React from "react";

export default function Lock(props: any) {
  return (
    <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.5 8C1.5 6.89543 2.39543 6 3.5 6H8.5C9.60457 6 10.5 6.89543 10.5 8V9.5C10.5 10.6046 9.60457 11.5 8.5 11.5H3.5C2.39543 11.5 1.5 10.6046 1.5 9.5V8Z" stroke="currentColor" stroke-width="1.46" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M3.5 6V4C3.5 2.61929 4.61929 1.5 6 1.5C7.38071 1.5 8.5 2.61929 8.5 4V6" stroke="currentColor" stroke-width="1.46" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  );
}


